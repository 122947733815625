import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Shop.css';

const Shop = () => {
    const [products, setProducts] = useState([]); // For gems
    const [bracelets, setBracelets] = useState([]); // For bracelets
    const [showForm, setShowForm] = useState(false);
    const [selectedBracelet, setSelectedBracelet] = useState(null);
    const [formData, setFormData] = useState({ name: '', birthdate: '', birthtime: '', birthplace: '' });
    const navigate = useNavigate();

    // Function to fetch bracelets from backend
    const fetchBracelets = async () => {
        try {
            const response = await fetch('https://api.astrodarshan14.com/api/allbracelate'); // Replace with your endpoint
            const data = await response.json();
            setBracelets(data);
        } catch (error) {
            console.error("Error fetching bracelets:", error);
        }
    };

    // Function to fetch gems from backend
    const fetchProducts = async () => {
        try {
            const response = await fetch('https://api.astrodarshan14.com/api/allproducts');
            const data = await response.json();
            setProducts(data);
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    // Fetch data on component mount
    useEffect(() => {
        fetchBracelets();
        fetchProducts();
    }, []);

    // Handle product checkout
    const handleCheckout = (product) => {
        navigate('/shop-details', { state: { product } });
    };

 

    // Show details for any bracelet
    const openForm = (bracelet) => {
        handleCheckout(bracelet);
    };

    return (
        <>
            <div className="as_main_wrapper" style={{marginBottom:"5px"}}>
                {/* Bracelets Section */}
                <section>
                    <div className="container">
                        <div style={{ textAlign: "center" }}>
                            <h1 className="as_heading as_heading_center" style={{ color: 'black', marginTop: '5vw', fontWeight: 'bold' }}>Bracelets</h1>
                            <p style={{ color: 'black' }}>Beautiful gemstone bracelets available for purchase.</p>
                        </div>

                        <div className="row">
                            {bracelets.map((bracelet) => (
                                <div key={bracelet.id} className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="as_product_box">
                                        <div className="as_product_img">
                                            <img
                                                src={`https://api.astrodarshan14.com/${bracelet.image_url}`}
                                                alt={bracelet.name}
                                                className="img-responsive"
                                                style={{ height: '50vh', objectFit: 'cover' }}
                                            />
                                        </div>
                                        <h4 style={{ color: 'black',fontWeight:'bold' }}>{bracelet.name}</h4>
                                        <p style={{color:"black"}}>{bracelet.description}</p>
                                        <span style={{ color: 'black',fontWeight:'bold' }}>₹{bracelet.price}</span>
                                    </div>
                                    <button className="as_btn" onClick={() => openForm(bracelet)}>
                                        View Product
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Gems Section */}
                <section>
                    <div className="container">
                        <div style={{ textAlign: "center" }}>
                            <h1 className="as_heading as_heading_center" style={{ color: 'black', marginTop: '10px', fontWeight: 'bold' }}>Gems</h1>
                            <p style={{ color: 'black' }}>We have authentic gemstones available for purchase.</p>
                        </div>

                        <div className="row">
                            {products.length > 0 ? (
                                products.map((product) => (
                                    <div key={product.id} className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="as_product_box">
                                            <div className="as_product_img">
                                                <img
                                                    src={`https://api.astrodarshan14.com/${product.image_url}`}
                                                    alt={product.name}
                                                    className="img-responsive"
                                                    style={{ height: '30vh', objectFit: 'cover' }}
                                                />
                                            </div>
                                            <h4 style={{ color: 'black',fontWeight:'bold' }}>{product.name}</h4>
                                            <p style={{ color: 'black' }}>{product.description}</p>
                                            <span style={{ color: 'black',fontWeight:'bold' }}>₹{product.price}</span>
                                        </div>
                                        <button className="as_btn" onClick={() => handleCheckout(product)}>View Product</button>
                                    </div>
                                ))
                            ) : (
                                <p>Loading products...</p>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Shop;
