import React from 'react'
import WhyChoose from '../components/WhyChoose'
import CommonTitle from '../components/CommonTitle'
import './Services.css'

const Service = () => {
    return (
        <>
            <div className="as_main_wrapper as_service_page" style={{backgroundColor:'white'}}>
               

                <section >
                    <div className="container" style={{backgroundColor:'white'}}>
                        <div className="row justify-content-center">
                            {/* First Row of Services */}
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-4" >
                                <div className="as_service_box text-center" >
                                <span className="as_icon" style={{marginLeft:'20vh'}}>
                                        <img src="assets/images/svg/service4.svg" alt="Rudraksha Icon" />
                                    </span>
                                   
                                    <h4 className="as_subheading">Kundli Analysis</h4>
                                    <p>Our Kundli analysis service provides detailed insights into your birth chart, based on the date, time, and place of your birth. It helps you understand your personality and future potential.</p>
                                    <a href="https://wa.link/1nof2c" className="as_link">Know More</a>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                <div className="as_service_box text-center">
                                <span className="as_icon" style={{marginLeft:'20vh'}}>
                                <img src="assets/images/bracelate (2).png" alt="Bracelets Icon" className="service-icon" />
                                </span>
                                    <h4 className="as_subheading">Bracelets</h4>
                                    <p>Explore our collection of bracelets, designed with spiritual significance. These bracelets are crafted to enhance your well-being and bring balance to your energy.</p>
                                    <a href="https://wa.link/1nof2c" className="as_link">Know More</a>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                <div className="as_service_box text-center">
                                    <span className="as_icon" style={{marginLeft:'20vh'}}>
                                        <img src="assets/images/svg/service3.svg" alt="Gemstones Icon" />
                                    </span>
                                    <h4 className="as_subheading">Gemstones</h4>
                                    <p>Our gemstone collection is designed to help you harness the power of the planets. Each gemstone is carefully selected to suit your specific astrological needs.</p>
                                    <a href="https://wa.link/1nof2c" className="as_link">Know More</a>
                                </div>
                            </div>

                            {/* Second Row of Services */}
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                <div className="as_service_box text-center">
                                <span className="as_icon" style={{marginLeft:'20vh'}}>
                                <img src="assets/images/rudraksh.png" alt="Rudraksha Icon" className="service-icon" />
                                </span>
                                    <h4 className="as_subheading">Rudraksha</h4>
                                    <p>Rudraksha beads are believed to hold spiritual and healing properties. We offer a range of Rudraksha products to bring peace, prosperity, and protection into your life.</p>
                                    <a href="https://wa.link/1nof2c" className="as_link">Know More</a>
                                </div>
                            </div>

                           



                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div className="as_service_box text-center" style={{ backgroundColor: '#031d2e', color: 'white' }}>
                    <span className="as_icon" style={{ marginLeft: '21vh' }}>
                    <img src='assets/images/fenshui.png' alt="Feng Shui Icon" className="service-icon" />                    </span>
                    <h4 className="as_subheading">Feng Shui Accessories</h4>
                    <p>Our Feng Shui products are created to promote positive energy flow and harmony in your surroundings.</p>
                    <a href="service_detail.html" className="as_link" style={{ color: 'white' }}>Read More</a>
                </div>
            </div>
                        </div>
                    </div>
                </section>


            </div>
        </>
    )
}

export default Service
