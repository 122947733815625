import React from 'react'
import { BsWhatsapp } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

const Footer = () => {

    function handleMailto() {
        window.location.href =
            "mailto:astrologer.shreedigimarketing.in?subject=Your%20Subject%20Here&body=Your%20message%20here.";
    }


    function handleCallNow() {
        const phoneNumber = "9226341599";
        window.location.href = `tel:+${phoneNumber}`;
    }
    return (
        <>
            <section className="as_footer_wrapper as_padderTop80" style={{ background: 'linear-gradient(90deg, #e66949, #fab855)'}}>
                <div className="container" >

                    <div className="as_footer_inner as_padderTop50 as_padderBottom80">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="as_footer_widget">
                                    <div className="as_footer_logo">
                                        <h3 style={{  color: "black", fontWeight:'bold'}}>Astrologer</h3>

                                    </div>
                                    <p style={{  color: "black" }}>An astrologer who provides precise and effective solutions for every problem in your life, ensuring happiness, peace, and prosperity through their guidance.</p>

                                    <div className="as_share_box">
                                        <p style={{  color: "black" ,fontWeight:'bold'}}>Follow Us</p>
                                        <ul>
                                            <li style={{  color: "black" }}><a href="facebook.com"><img src="assets/images/svg/facebook.svg" alt="" /></a></li>
                                            <li style={{  color: "white" }}><a href="https://wa.link/1nof2c"><FaWhatsapp /></a></li>
                                            <li style={{  color: "white" }}><a href="https://www.instagram.com/astrodarshan14/profilecard/?igsh=amczdDBiem1jYTk3">< FaInstagram  /></a></li>
                                            <li style={{  color: "black" }}><a href="https://wa.link/1nof2c"><img src="assets/images/svg/youtube.svg" alt="" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="as_footer_widget">
                                    <h3 className="as_footer_heading"style={{  color: "black" ,fontWeight:'bold' }}>Our Services</h3>

                                    <ul>
                                        <li style={{  color: "black" }}><a href="/">Kundli analysis </a></li>
                                        <li style={{  color: "black" }}><a href="/shop"><span></span> Bracelets</a></li>
                                        <li style={{  color: "black" }}><a href="/shop"><span></span> Gemstone </a></li>
                                        <li style={{  color: "black" }}><a href="/shop"><span></span> Rudraksha</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="as_footer_widget">
                                    <h3 className="as_footer_heading" style={{  color: "black",fontWeight:'bold' }}>Quick Links</h3>

                                    <ul>
                                    <li style={{  color: "black" }}><a href="/"><span>   </span> Home</a></li>
                                        <li style={{  color: "black" }}><a href="about"><span> </span> About Us</a></li>
                                        <li style={{  color: "black" }}><a href="shop"><span>  </span> Shop</a></li>
                                       
                                    
                                        <li style={{  color: "black" }}><a href="contact"><span></span> Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="as_footer_widget">
                                    <h3 className="as_footer_heading" style={{  color: "black" ,fontWeight:'bold'}}>Stay in Touch</h3>

                                    <ul className="as_contact_list">
                                        <li>
                                            <img src="assets/images/svg/map.svg" alt="" />
                                            <p style={{  color: "black" }}>17 Suman residency , 10 Ganpati nagar ,Jalgaon 425001</p>
                                        </li>
                                        <li>
                                            <img src="assets/images/svg/address.svg" alt="" />
                                            <p>
                                                <a onClick={handleMailto} style={{  color: "black" }}>astrodarshan@gmail.com</a>
                                            </p>
                                        </li>
                                        <li>
                                            <img src="assets/images/svg/call.svg" alt="" />
                                            <p>
                                                <a onClick={handleCallNow} style={{  color: "black" }}>9226341599</a><br></br>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="as_copyright_wrapper text-center" style={{backgroundColor:'#542e00'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12" style={{color:"white"}}>
                            <p>&copy; Copyright {new Date().getFullYear()}, All Rights Reserved, <a href="#" style={{ color: "white" }}>Astro Darshan</a> &nbsp;&nbsp;Developed by <a href="www.shreedigimarketing.in" style={{ color: "#EFCC00" }}>Shree Digital Marketing Agency</a></p>
                        </div>
                    </div>
                </div>
            </section>

            <a
                href="https://wa.me/919226341599?text=Hello I found this number on your website and would like to know more about your services "
                target="_blank"
            >
                <BsWhatsapp className="wp-float-btn" />
            </a>

        </>
    )
}

export default Footer
