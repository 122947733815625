import React from 'react'
import { Route, Routes } from "react-router-dom"
import Home from './pages/Home'
import About from './pages/About'
import Service from './pages/Service'
import Shop from './pages/Shop'
import Contact from './pages/Contact'
import Review from './pages/Review'
import Header from './components/Header'
import Footer from './components/Footer'
import Checkout from './pages/Checkout'
import Shopdetails from './pages/Shopdetails'

const APP_ROUTES = [
  { path: "/", element: <Home /> },
  { path: "/about", element: <About /> },
  { path: "/service", element: <Service /> },
  { path: "/shop", element: <Shop /> },
  { path: "/review", element: <Review /> },
  { path: "/contact", element: <Contact /> },
  { path: "/checkout", element: <Checkout /> },
  { path: "/shop-details", element:<Shopdetails/>}
]

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        {APP_ROUTES?.map((item, index) => (
          <Route key={index} path={item.path} element={item.element} />
        ))}
      </Routes>
      <Footer />
    </>
  )
}

export default App


