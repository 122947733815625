import React from 'react';
import { useLocation } from 'react-router-dom';
import './Shopdetails.css'; // Import the CSS file for styling

const ShopDetails = () => {
    const location = useLocation();
    const { product } = location.state || {}; // Access the passed product data

    if (!product) {
        return (
            <div className="no-product">
                <p>No product details available. Please go back to the shop page.</p>
            </div>
        );
    }

    return (
        <div className="shop-details-container">
            {/* Product Details Section */}
            <div className="product-card">
                {/* Left Section: Product Image */}
                <div className="product-image-section">
                    <img
                        src={`https://api.astrodarshan14.com/${product.image_url}`}
                        alt={product.name}
                        className="product-image"
                    />
                    
                </div>

                {/* Right Section: Product Info */}
                <div className="product-info-section">
                    <h1 className="product-title">{product.name}</h1>
                    <div className="product-reviews">
                        <span>⭐⭐⭐⭐⭐</span>
                        <span className="review-count">3194 Reviews</span>
                    </div>
                    <h2 className="product-price">
                        <span className="original-price">₹4,101.00</span> ₹{product.price}
                    </h2>
                    <p className="product-description">
                        {product.description || 'No description available.'}
                    </p>
                    {/* CTA Buttons */}
                    <div className="product-buttons">
                        <a href='https://wa.link/5xiqtq'><button className="btn btn-primary ms-2">Buy Now</button></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShopDetails;
