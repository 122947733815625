import React, { useState } from 'react'
import WhyChoose from '../components/WhyChoose'
import WhatCustomer from '../components/WhatCustomer'

const Home = () => {

    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        birthDate: "",
        birthTime: "",
        birthPlace: "",
    });
    const [result, setResult] = useState("")

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = () => {
        const rashi = getRashi(formData.birthDate);
        setResult(`आपले राशि नाव: ${rashi}`);
        handleShow();
    };

    return (
        <>
          <div className="as_main_wrapper" >
          <section className="as_banner_wrapper"> 
    <div className="container-fluid"  style={{marginRight:'-3vh'}}>
        <div className="row as_verticle_center">
            <div className="col-lg-6 col-md-6 col-sm-12 order-1">
                <div className="as_banner_detail" style={{marginTop:'1vw',marginRight:'3vh'}} >
                    <h1 style={{ fontFamily: 'Roboto' }}>10,000+ Satisfied  Consultations Done</h1>
                    <p >
                    Welcome to Astrodarshan, where ancient wisdom meets modern insight. Our astrology services are designed to guide you in understanding your unique path, illuminating your journey in life, relationships, career, and personal growth. Whether you’re seeking direction or answers, our expert guidance through the stars and planets can provide the clarity you need.                    </p>
                    <p >
                    Book a 30-minute consultation for ₹3000 to gain tailored insights into your life’s pressing questions. Let us help you unlock your potential and navigate life’s challenges with confidence.                    </p>
                    <a href="https://wa.link/1nof2c" className="as_btn">consult Know</a>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 order-2" >
                <div className="as_banner_img text-center" >
                    <img 
                        src="assets/images/chakra.png" 
                        alt="" 
                        className="img-responsive rotate-img" 
                        style={{ width: '100%',  marginBottom: '30vw !imporant',marginLeft:'-1vw' }} 
                    />
                </div>
            </div>
        </div>
    </div>

  
</section>




<section className="as_about_wrapper as_padderTop80 as_padderBottom80">
    <div className="container" >
        <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="as_about_slider">
                    <div>
                        <div className="as_aboutimg text-right">
                            <img 
                                src="assets/images/about1.jpg" 
                                alt="" 
                                className="img-responsive about-img" 
                                style={{ width: '70%', height: '50%', maxWidth: '100%'  }} // Ensure responsive image
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <h1 className="as_heading">Know about Astrology</h1>
                <p >Astrology is an ancient science that studies the influence of celestial bodies on human life and the natural world. Rooted in centuries-old knowledge, astrology connects the positions of planets, stars, and other cosmic forces with events on Earth, giving us insights into personality traits, relationships, health, and even career paths.</p>
                <p >Unlock the mysteries of the universe and uncover your unique potential through the art and science of astrology. At Astrodarshan, we blend ancient wisdom with modern insight, guiding you to understand your life’s journey, relationships, career, and inner self through the stars and planets.Whether you seek guidance on relationships, career, or personal growth, we are here to help you find clarity and direction.
                </p>
                <p >Astrology doesn’t predict exact events but provides a deeper understanding of personal strengths, weaknesses, and potential life paths. By studying planetary alignments and transits, astrologers offer guidance on making decisions, building relationships, and navigating life changes.</p>
                <a href="https://wa.link/1nof2c" className="as_btn">Know More</a>

               

            </div>
        </div>
    </div>

    <style >{`
        .as_aboutimg {
            text-align: right; 
        }
       
        .as_contact_expert {
            flex-direction: column; /* Stack icon and text vertically */
            align-items: center; /* Center items vertically */
            margin-left: 0; /* Reset margin for mobile */
            justify-content: center; /* Center items horizontally */
        }
        .as_icon {
            margin-bottom: 10px; /* Space below the icon */
            margin-left: 10px; /* Reset left margin */
            
        }
        div > div {
            margin-left: 0; /* Remove left margin for mobile */
           
        }
    }
    `}</style>
</section>


<section className="as_service_wrapper as_padderTop80 as_padderBottom80">
    <div className="container">
        <div className="row">
            <div className="col-lg-12 text-center">
                <h1 className="as_heading as_heading_center" style={{color:'black',fontWeight:'bold'}}>Our Services</h1>
                <p className="as_font14 as_padderBottom5" style={{color:'black',fontWeight:'bold'}}>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
        </div>

        <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="as_service_box text-center" style={{ backgroundColor: '#17384e', color: 'white' }}>
                    <span className="as_icon">
                        <img src="assets/images/svg/service4.svg" alt="Kundli Analysis Icon" className="service-icon" />
                    </span>
                    <h4 className="as_subheading">Kundli Analysis</h4>
                    <p>Gain deep insights into your life's journey through Kundli analysis, exploring the impact of planetary positions on your career, health, and relationships. Our experts provide guidance tailored to your unique astrological chart.</p>
                    <a href="https://wa.link/1nof2c" className="as_link" style={{ color: 'white' }}>Know More</a>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="as_service_box text-center" style={{ backgroundColor: '#ffce61', color: 'white' }}>
                    <span className="as_icon">
                        <img src="assets/images/bracelate (2).png" alt="Bracelets Icon" className="service-icon" />
                    </span>
                    <h4 className="as_subheading">Bracelets</h4>
                    <p>Our spiritual and healing bracelets are crafted to promote balance and well-being. Each bracelet aligns with astrological energies, enhancing clarity and supporting emotional stability.</p>
                    <a href="/shop" className="as_link" style={{ color: 'white' }}>Know More</a>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="as_service_box text-center" style={{ backgroundColor: '#17384e', color: 'white' }}>
                    <span className="as_icon">
                        <img src="assets/images/svg/service3.svg" alt="Gemstones Icon" className="service-icon" />
                    </span>
                    <h4 className="as_subheading">Gemstone</h4>
                    <p>Astrologically chosen gemstones help attract positive energy and neutralize negative influences. Find the ideal gemstone to boost harmony and luck based on your personal chart.</p>
                    <a href="/shop" className="as_link" style={{ color: 'white' }}>Know More</a>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="as_service_box text-center" style={{ backgroundColor: 'rgb(37, 150, 190)', color: 'white' }}>
                    <span className="as_icon">
                        <img src="assets/images/rudraksh.png" alt="Rudraksha Icon" className="service-icon" />
                    </span>
                    <h4 className="as_subheading">Rudraksha</h4>
                    <p>Rudraksha beads offer spiritual protection and peace. Known for their healing qualities, these sacred beads help reduce stress and promote a calm, focused mind.</p>
                    <a href="https://wa.link/1nof2c" className="as_link" style={{ color: 'white' }}>Know More</a>
                </div>
            </div>

          

            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="as_service_box text-center" style={{ backgroundColor: '#17384e', color: 'white' }}>
                    <span className="as_icon">
                        <img src='assets/images/fenshui.png' alt="Feng Shui Icon" className="service-icon" />
                    </span>
                    <h4 className="as_subheading">Feng Shui Accessories</h4>
                    <p>Our Feng Shui products are designed to create balance and positive energy flow in your home or workplace, helping you cultivate harmony and attract prosperity.</p>
                    <a href="https://wa.link/1nof2c" className="as_link" style={{ color: 'white' }}>Know More</a>
                </div>
            </div>
        </div>
    </div>
</section>
    <WhatCustomer />
</div>

        </>
    )
}

export default Home